

























































































































































































































































































































































































































































import ResourceDownloadsModal from '@/components/views/components/ResourceDownloadsModal.vue'
import config from '@/config'
import AvatarView from '@/components/views/AvatarView.vue'
import { Api, PagedResults, request, TableState, UserModel } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  components: {
    AvatarView,
    ResourceDownloadsModal
  }
})

export default class UsersView extends Mixins(ComponentHelper) {
  userData!: PagedResults<UserModel>
  searchPhrase = this.$route.query.q as string || ''
  loading: boolean = false
  selectedUser: UserModel | null = null
  showEditUser: boolean = false
  schoolId = this.$route.query.schoolid || ''
  resourceDownloadsModal: boolean = false
  resourceDownloadProps: any = {}
  sessions: UserModel[] = []
  table: TableState = {
    page: 1,
    perPage: 20,
    sort: 'name',
    dir: 'asc',
    term: ''
  }

  searchType: string = 'All'

  @Watch('searchPhrase')
  onChange () {
    if (/^\d+$/.test(String(this.searchPhrase).trim())) { // Regex checks whether the query only contains numbers
      this.searchType = 'ID'
    } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.searchPhrase.trim()) || /^@[^\s@]+\.[^\s@]+$/.test(this.searchPhrase.trim())) { // Regex checks whether an email address has been entered
      this.searchType = 'Email'
    } else if (this.searchPhrase.startsWith('@')) {
      this.searchType = 'Username'
    } else if (this.searchPhrase === '' && this.schoolId !== undefined) {
      this.searchType = 'All'
    } else {
      this.searchType = 'All'
    }
  }

  mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    }
    if (this.$route.query.q || this.$route.query.schoolid) {
      this.searchDidChange()
    }
  }

  detailsOpen (user: UserModel) {
    this.getUsersSession(user.id)
  }

  onPageChange (page: number) {
    this.table.page = page
    this.getUsers()
  }

  onSortChange (sort: string, dir: 'asc' | 'desc') {
    this.table.dir = dir
    this.table.sort = sort
    this.getUsers()
  }

  async getUsersSession (userId: number) {
    const session = await Api.getUsersSession(userId)
    this.sessions.push(session)
  }

  closeResourceDownloadsModal () {
    this.resourceDownloadsModal = false
  }

  getResourceDownloads (userId) {
    this.resourceDownloadProps = { userId }
    this.resourceDownloadsModal = true
  }

  setSchoolActive (user, school) {
    console.log(user.id)
    console.log(school)
    request('POST', 'superuser/schools/' + school + '/users/' + user.id + '/activate', null)
      .then((response) => {
        this.loading = false
        // var data = response.data
        this.searchDidChange()
        // console.log('readerData: ' + this.userData)
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }

  removeUserFromSchool (user, school) {
    console.log(user.id)
    console.log(school)
    request('DELETE', 'superuser/schools/' + school + '/users/' + user.id, null)
      .then((response) => {
        this.loading = false
        // var data = response.data
        this.searchDidChange()
        // console.log('readerData: ' + this.userData)
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }

  addUserToSchool (user) {
    const p = prompt('Add teacher to School ID?')
    if (p) {
      console.log(p)
      request('POST', 'superuser/schools/' + p + '/users/' + user.id, null)
        .then((response) => {
          this.loading = false
          // var data = response.data
          this.searchDidChange()
          // console.log('readerData: ' + this.userData)
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }
  }

  verifyEmail (email) {
    request('POST', 'superuser/verifyemail', { email })
      .then((response) => {
        this.loading = false
        // var data = response.data
        this.searchDidChange()
        // console.log('readerData: ' + this.userData)
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }

  searchDidChange () {
    if (this.searchPhrase.length >= 1 || this.schoolId) {
      this.getUsers()
    }
  }

  getUsers () {
    const searchParams: {q, schoolid, searchtype} = {
      q: '',
      schoolid: undefined,
      searchtype: this.searchType
    }

    if (this.searchPhrase) {
      searchParams.q = this.searchPhrase
    }
    if (this.schoolId) {
      searchParams.schoolid = this.schoolId
    }

    searchParams.q = searchParams.q.trim()

    const requestParams = {
      ...searchParams,
      page: this.table.page,
      perPage: this.table.perPage,
      skip: (this.table.page - 1) * this.table.perPage,
      take: this.table.perPage,
      sort: this.table.sort,
      dir: this.table.dir
    }

    if (this.searchType === 'Email') {
      searchParams.searchtype = 'Email'
      requestParams.searchtype = 'Email'
    }

    if (this.searchType === 'ID') {
      searchParams.searchtype = 'ID'
      requestParams.searchtype = 'ID'
      if (!/^\d+$/.test(searchParams.q)) {
        this.$buefy.toast.open({
          message: 'Please only enter a number when search type is set to ID.',
          position: 'is-top',
          type: 'is-danger',
          duration: 3000
        })
        return
      }
    }

    if (this.searchType === 'All') {
      searchParams.searchtype = 'All'
      requestParams.searchtype = 'All'
      if (searchParams.q.length < 3 && !this.schoolId) {
        this.$buefy.toast.open({
          message: 'Please enter at least three characters when search type is set to all, or enter a school ID.',
          position: 'is-top',
          type: 'is-danger',
          duration: 3000
        })
        return
      }
    }

    this.loading = true
    this.updateQueryParams(() => searchParams)

    request('GET', 'users', requestParams)
      .then((response) => {
        this.loading = false
        const data = response.data
        this.userData = data.users
        // console.log('readerData: ' + this.userData)
      })
      .catch((error) => {
        console.log(error)
        this.loading = false
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
      })
  }

  editUser (user: UserModel) {
    this.selectedUser = user
    this.showEditUser = true
  }

  onCloseEditUser () {
    this.selectedUser = null
    this.showEditUser = false
  }

  setPassword (user) {
    const p = prompt('Enter new password.')
    console.log(p)
    if (p && p.length > 5) {
      request('PUT', 'superuser/users/' + user.id, { password: p })
        .then((response) => {
          const data = response.data
          if (data.error) {
            alert(data.error)
          } else {
            this.$buefy.toast.open('Success')
          }
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }
  }

  async setRole (userId, schoolId, role) {
    await Api.setRoleForSchoolUser(userId, schoolId, role)
    this.$buefy.toast.open('Success')
    this.getUsers()
  }

  deleteUser (user) {
    console.log('DELETE USER')
    const c = confirm('Are you sure you want to delete this user? This cannot be undone.')
    if (c) {
      request('DELETE', 'superuser/users/' + user.id, null)
        .then((response) => {
          const data = response.data
          if (data.error) {
            alert(data.error)
          } else {
            this.$buefy.toast.open({ message: 'Success', type: 'is-success' })
          }
          this.getUsers()
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    }
  }

  beforeSavePupil () {
    console.log(this.selectedUser)
    if (!this.selectedUser) {
      return
    }
    // validate + save
    // name, username, email format
    let ok = true
    if (this.selectedUser.name.length < 2) {
      ok = false
      alert('Name error')
    }

    if (this.selectedUser.username.length < 2) {
      ok = false
      alert('Name error')
    }

    if (ok) {
      const params: any = { name: this.selectedUser.name, username: this.selectedUser.username, locale: this.selectedUser.locale }
      if (this.selectedUser.email && this.selectedUser.email !== '') {
        params.email = this.selectedUser.email
      }
      if (this.selectedUser.display_name && this.selectedUser.display_name !== '') {
        params.display_name = this.selectedUser.display_name
      }

      params.upn = this.selectedUser.upn?.trim() === '' ? null : this.selectedUser.upn

      request('PUT', 'superuser/users/' + this.selectedUser.id, params)
        .then((response) => {
          const data = response.data
          if (data.error) {
            alert(data.error)
          } else {
            this.$buefy.toast.open('Success')
            this.onCloseEditUser()
            this.searchDidChange()
          }
        })
        .catch((error: unknown) => {
          if (error instanceof Error) {
            this.$buefy.toast.open({
              message: error.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        })
    }
  }

  immitateUser (userId) {
    window.location.href = `${config.authURI}immitateuser?user_id=${userId}`
  }

  async recalculateScoreCache (userId) {
    try {
      await Api.recacheUsersArchivedScores(userId)
      this.$buefy.toast.open({
        message: 'Updated archived scores',
        position: 'is-bottom',
        type: 'is-success'
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not calculate scores',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async recalculateTotalScores (userId) {
    try {
      await Api.recacheUsersTotalScores(userId)
      this.$buefy.toast.open({
        message: 'Updated total scores',
        position: 'is-bottom',
        type: 'is-success'
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not calculate scores',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async recalculateShedScores (userId) {
    try {
      await Api.recacheUsersShedScores(userId)
      this.$buefy.toast.open({
        message: 'Updated shed scores',
        position: 'is-bottom',
        type: 'is-success'
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not calculate scores',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async refreshUserSession (id: number) {
    try {
      this.loading = true
      const newUser = await Api.refreshUser(id)
      this.$buefy.toast.open({
        message: 'Updated user\'s session',
        position: 'is-bottom',
        type: 'is-success'
      })

      const index = this.sessions.findIndex(s => s.id === newUser.id)

      if (index === -1) {
        this.sessions.push(newUser)
      } else {
        this.sessions.splice(index, 1, newUser)
      }
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not refresh session',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }
}
